$font-path: "../../../public/fonts/MaisonNeue";

@font-face {
  font-family: 'MaisonNeue';
  src:
    local('MaisonNeue'), local('MaisonNeue-Book'),
    url("#{$font-path}/MaisonNeue-Book.woff2") format('woff2'),
    url("#{$font-path}/MaisonNeue-Book.woff") format('woff'),
    url("#{$font-path}/MaisonNeue-Book.ttf") format('truetype');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MaisonNeue';
  src: 
    local('MaisonNeue'), local('MaisonNeue-Medium'),
    url("#{$font-path}/MaisonNeue-Medium.woff2") format('woff2'),
    url("#{$font-path}/MaisonNeue-Medium.woff") format('woff'),
    url("#{$font-path}/MaisonNeue-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MaisonNeue';
  src: 
    local('MaisonNeue'), local('MaisonNeue-Bold'),
    url("#{$font-path}/MaisonNeue-Bold.woff2") format('woff2'),
    url("#{$font-path}/MaisonNeue-Bold.woff") format('woff'),
    url("#{$font-path}/MaisonNeue-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
