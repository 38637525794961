@import '../../styles/variables';

.labeled-input {
  flex: 1;
  justify-content: space-between;
  font-size: $font-size-small;

  .labeled-input-wrap {
    position: relative;

    .right {
      position: absolute;
    }
  }

  .labeled-input-label {
    display: inline-block;
    margin-bottom: 6px;
  }

  .labeled-input-error {
    display: inline-block;
    margin-top: 6px;
  }
}
