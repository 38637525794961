@import '../../styles/variables';

.suspension-search-select-container {
  position: relative;

  .selected-item-name,
  .no-image-title,
  .item-subtitle {
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .selected-item-name-container {
    display: flex;
    align-items: center;
  }

  .no-image-title {
    font-size: 14px;
  }

  .suspension-search-select {
    max-width: unset;
    padding-bottom: 0;
    padding-right: 0;
  }

  .suspension-search-results {
    position: absolute;
    top: 46px;
    left: 0;
    right: 0;
    background: #27303e;
    box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 8px 8px;

    .item-row {
      min-height: 40px;
      padding: 6px 0px 6px 8px;
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
      }
    }

    .item-row-name {
      font-size: 14px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .item-subtitle {
      margin-top: 5px;
      font-size: 12px;
      color: #cfd1d4;
    }

    .suspension-search-results-loading-spinner {
      height: 46px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 8px;
      padding-bottom: 16px;
    }

    .no-image-item-bottom-border {
      position: absolute;
      bottom: -10px;
      left: 0px;
      right: 4px;
      height: 1px;
      background-color: #3f4753;
    }

    .search-results-no-results {
      height: 46px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 8px;
      padding-bottom: 16px;
    }
  }

  .suspension-search-input-container {
    display: flex;
    width: 100%;
    padding: 8px 30px 8px 16px;
    height: 46px;
    background: #27303e;
    border: 1px solid #3f4753;
    border-radius: 4px;

    &:hover {
      border-color: white;
    }

    input:active &,
    input:focus & {
      border-color: white;
    }
  }

  .suspension-search-no-search-text {
    cursor: pointer;
    align-self: center;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: normal;

    color: white;
    opacity: 0.5;
  }
}

.suspension-search-input {
  width: 100%;
  border: 0;
  font-family: $font-body;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1.2px;
  color: #ffffff;

  &::placeholder {
    color: white;
    opacity: 0.5;
  }
}

.add-suspension-sheet {
  display: flex;
  flex-direction: column;
  flex: 1;
  // Removing this due to layout issue reported by James
  // margin-top: -44px;

  .add-suspension-description {
    margin-bottom: 36px;
    line-height: 18px;

    p {
      margin: 2px 0;
    }
  }

  .add-suspension-required-fields {
    margin: 8px 0;
    text-align: right;
  }

  .suspension-select-container {
    width: 50%;
    max-width: unset;

    padding-right: 0;
    padding-bottom: 0;

    &:first-of-type {
      padding-right: 8px;
    }

    &:last-of-type {
      padding-left: 8px;
    }

    &:only-child {
      padding-left: 0;
    }
  }

  .add-report-row {
    padding-bottom: 24px;
  }

  .add-report-submission-row {
    margin-top: 40px;
  }

  .report-select {
    max-width: unset;
    width: 100%;
    height: 46px;
  }

  .picker-label {
    height: 12px;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 8px;
    letter-spacing: 1.04px;
  }

  .picker-value-container {
    background: #27303e;
    border: 1px solid #3f4753;
    border-radius: 4px;
    height: 46px;
  }
}

.is-canlan .suspension-search-input-container {
  border-width: 2px;
}

.is-canlan .picker-value-container {
  border-width: 2px;
}

.add-suspension-button {
  &:first-of-type {
    margin-right: 8px;
  }

  &:last-of-type {
    margin-left: 8px;
  }

  &.is-cancel {
    background: transparent;
    border-color: #9fa3a9;

    &:hover {
      border-color: white;
    }
  }
}

.suspension-delete-container {
  display: flex;
  margin-top: auto;
  align-items: flex-end;
  justify-content: flex-end;
}
