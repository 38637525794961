.driver-popover {
    border-radius: 8px;
    max-width: 550px;
    max-height: 550px;
}

.driver-popover.driverjs-theme {
  background-color: #27303e;
}

.driver-popover.driverjs-theme .driver-popover-title {
  font-size: 18px;
  color: #d9a30a;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 24px;
}

.driver-popover.driverjs-theme .driver-popover-description {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}
.driver-popover.driverjs-theme .driver-popover-progress-text {
  color: #d9a30a;
}

.driver-popover.driverjs-theme button {
  flex: 1;
  text-align: center;
  background-color: #f2b400;
  color: #27303e;
  border: 2px solid #f2b400;
  text-shadow: none;
  font-size: 16px;
  padding: 5px 8px;
  border-radius: 8px;
  max-width: 150px;
}

.driver-popover.driverjs-theme button:hover {
  color: #ffffff;
}

.driver-popover.driverjs-theme .driver-popover-navigation-btns {
  justify-content: flex-end;
  gap: 3px;
}

.driver-popover.driverjs-theme .driver-popover-close-btn {
  color: #B2B3B5;
  background-color:#27303e;
  border: #27303e;
  font-size: 20px;
  padding: 8px 8px;
  width: 16px;
  height: 16px;
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: #27303e;
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: #27303e;
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: #27303e;
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: #27303e;
}

.driver-popover-next-btn {
  max-width: 100px !important;
}

// .driver-active-element {
//   border: 2px solid #fde047;
//   border-radius: 8px;
// }