/* styles.scss */
.ant-message {
    z-index: 10001!important;
  }
.ant-tabs-left {
  >.ant-tabs-nav {
    .ant-tabs-tab {
      text-align: left;
      width: 200px;
      height: 40px;
    }
    .ant-tabs-ink-bar {
      height: 40px !important;
      left: 0px !important;
    }
  }
  >.ant-tabs-content-holder {
    >.ant-tabs-content {
      >.ant-tabs-tabpane {
        padding-left: 0px;
        height: 100%;
      }
    }
  }
}
.ant-tabs {
  >.ant-tabs-nav {
    background: #1B222B!important;
    border-bottom: 1px solid #3F4753;
  }
  .ant-tabs-tab {
    margin-top: 0px !important;
    &.ant-tabs-tab-active {
      background-color: #27303E !important; 
    }
    .ant-tabs-tab-btn {
      font-family: 'Inter', Arial, Helvetica, sans-serif !important;
      font-size: 16px;
      font-weight: 500;
      line-height: 145%;
    }
    &[aria-selected="false"] {
      color: #F2B400!important;
    }
    // apply a style if aria-selected="false"
    &[aria-selected="false"] {
      color: #ffffff!important;
    }
  }
  .ant-tabs-content {
    height: 100%;
  }
}
.ant-input-outlined {
  border-radius: 4px;
  border: 1px solid var(--Neutrals-Gray60, #3F4753);
  background: var(--Neutrals-Gray70, #27303E);
  font-family: 'Inter', Arial, Helvetica, sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 20.3px */
  color: #ffffff;
  padding: 8px 12px;
}