@import '../../styles/variables';

// Overrides for mobile, which we will use up to 863px (just before ipad in portrait)
@media only screen and (max-width: 863px) {
  .invite-v2,
  .invite-section,
  .invite-accept,
  .invite-picker {
    width: 100% !important;
    display: block !important;
    float: none !important;
  }

  .invite-v2 {
    min-height: unset;

    .right-fade,
    .invite-picker-spacer {
      display: none;
    }

    .invite-mobile-scroller {
      margin-top: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      width: 36px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 50%;

      span {
        font-size: 24px;
        color: white !important;
      }
    }

    .invite-section {
      min-height: unset;
      padding: 0;
      padding-top: 64px;
      padding-right: 0;

      &.left {
        h1,
        h2,
        .accept-button-wrap {
          padding: 0 24px;
        }

        &:not(.remove-border) {
          border-right: 0 !important;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
      }

      &.right {
        justify-content: center;
        margin-top: 0;
        padding: 68px 24px 56px;
      }
    }

    .invite-title {
      font-size: 44px;
      line-height: 44px;
      letter-spacing: 0.64px;
    }

    .invite-picker {
      padding: 68px 24px 56px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);

      h2 {
        padding: 0 !important;
      }
    }

    .invite-picker-users {
      flex-flow: row wrap;
      overflow: visible;
      margin-bottom: -40px; // Remove extra space due to last items in row
    }

    .invite-picker-item {
      flex: 0 0 50%;

      @media only screen and (min-width: 460px) {
        flex-basis: 33%;
      }

      @media only screen and (min-width: 592px) {
        flex-basis: 25%;
      }

      &:not(:first-child) {
        margin-left: unset;
      }

      margin-bottom: 40px;
      max-width: unset;
      min-height: 225px;

      .invite-btn {
        max-width: 108px;
        opacity: 1;
        background: none;

        border: 1px solid rgba(255, 255, 255, 0.2);
        &:focus,
        &:active {
          border: 1px solid white;
        }
      }
    }

    .invite-form {
      padding-right: 0;
      width: unset;
    }

    .left .invite-form {
      margin-top: 0;
      padding: 0 24px;

      .invite-sub-title {
        padding: 0;
      }
    }

    .accept-button-wrap {
      margin-bottom: 40px;
    }
  }
}
