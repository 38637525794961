$main-nav-height: 56px;
$menu-height: 52px;

$font-size: 14px;
$font-size-small: 12px;

$color-text: white;
$color-white: rgba(255, 255, 255, 0.05);
$color-flair: #ffa300;
$color-flair-light: #ffa400;

$color-border: #2f3134;
$color-background: #181a1d;
$color-background-light: #282e38;

// z-indexes begin at 1000 because of react-leaflet :(
$z-index-base: 1000;
$z-index-modal: $z-index-base + 40;
$z-index-menu: $z-index-base + 20;

$border: 1px solid $color-border;

$font-title: 'Rift', Arial, Helvetica, sans-serif;
$font-body: 'MaisonNeue', Arial, Helvetica, sans-serif;
