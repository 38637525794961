@import '../../styles/variables';

.sn-pagination {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $menu-height;
  z-index: $z-index-base;

  justify-content: center;
  align-items: center;

  background-color: $color-background;
  border-top: 1px solid $color-border;

  @media only screen and (max-width: 863px) {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background-color: $color-background-light;
  }

  &.no-position-fixed {
    position: relative;
  }

  &.no-border {
    border-top: 0;
  }

  &.no-background {
    background-color: unset;
  }
}

.page-buttons {
  justify-content: space-between;

  li {
    margin: 0 2px;
  }
}

.page-button {
  position: relative;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;

  border: 1px solid $color-text;
  border-radius: 2px;

  opacity: 0.4;

  color: white;

  &.is--active {
    opacity: 1;

    &:hover {
      border-color: $color-flair;
    }
  }

  &.is--current {
    border-color: $color-flair;
    cursor: default;
    opacity: 0.8;
  }

  &.small-text {
    font-size: $font-size-small;
  }

  &.smaller-text {
    font-size: $font-size-small - 3;
  }
}
