$font-path: "../../../public/fonts";

@font-face {
  font-family: 'BarlowCondensed';
  src:
    local('BarlowCondensed-Bold'), local('BarlowCondensed-Bold'),
    url("#{$font-path}/barlowCondensed/BarlowCondensed-Bold.ttf") format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'BarlowCondensed';
  src:
    local('BarlowCondensed-Medium'), local('BarlowCondensed-Medium'),
    url("#{$font-path}/barlowCondensed/BarlowCondensed-Medium.ttf") format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'BarlowCondensed';
  src:
    local('BarlowCondensed-Regular'), local('BarlowCondensed-Regular'),
    url("#{$font-path}/barlowCondensed/BarlowCondensed-Regular.ttf") format('truetype');
  font-weight: normal;
}
