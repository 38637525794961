@import '../../styles/variables';

.invite-register {
  .invite-form {
    margin-top: 56px;
    width: unset;
  }

  .invite-register-dob {
    margin-bottom: 20px;
  }

  .invite-register-success {
    margin-bottom: 64px;
    line-height: 1.2;

    p {
      margin-bottom: 20px;
    }

    input {
      height: 44px;
      background-color: $color-background;
      margin-bottom: 20px;
    }

    button {
      margin: 0 auto;
      height: 44px;
      width: 55%;
    }
  }

  .invite-password .right {
    right: 7px;
    top: 26%;
  }

  @media only screen and (max-width: 767px) {
    .invite-form {
      padding: 0 24px 24px !important;
      margin-top: 4vh;
    }

    .invite-register__2-up {
      display: block;
  
      .invite-link {
        margin: 16px 0;
        text-align: left;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .invite-copy {
      width: 305px;
    }

    .invite-form .invite-link {
      text-align: right;
      flex: 1;
      margin-left: 24px;
    }

    .invite-register__2-up {
      display: flex;
  
      .invite-register-btn {
        flex: 1;
      }
  
      > .labeled-input:not(:first-child) {
        flex: 1;
        margin-left: 16px;
      }
    }
  }
}

.invite-register__sign-in {
  margin-top: 4vh;
  font-size: 14px;

  @media only screen and (max-width: 767px) {
    padding: 0 24px 24px;

    border-bottom: 1px solid $color-border;
  }
}
