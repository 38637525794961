$font-path: "../../../public/fonts";

@font-face {
  font-family: 'Inter';
  src:
    local('Inter-Bold'), local('Inter-Bold'),
    url("#{$font-path}/inter/Inter-Bold.ttf") format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter-Medium'), local('Inter-Medium'),
    url("#{$font-path}/inter/Inter-Medium.ttf") format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter-Regular'), local('Inter-Regular'),
    url("#{$font-path}/inter/Inter-Regular.ttf") format('truetype');
  font-weight: normal;
}
