@import 'styles/reset';
@import 'styles/flex';

html {
  // Disables double-tap to zoom on touch devices, preventing browser delay when
  // detecting click events.
  touch-action: manipulation;

  font-variant-ligatures: none;

  // Avoid horizontal scrolling when vertical scrollbar is present
  overflow-x: hidden;
}

body {
  background-repeat: no-repeat;
  background-attachment: fixed;

  // Iframe widget shows a flash of regular background color during load sometimes
  &.in-iframe {
    background: white;
  }
}

main {
  display: block; // Thanks, IE11
}

// Hide the "clear" button in search boxes, which clobbers our own search/clear
// button
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;

  letter-spacing: 0; // Ensure icons are centre-aligned
}

.is--error {
  color: red;
}

.is--clickable {
  cursor: pointer;
}

.u--underline {
  text-decoration: underline;
}

.is--disabled {
  opacity: 0.5;
  cursor: normal;
  pointer-events: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.password-reveal {
  cursor: pointer;
  pointer-events: all;
}
