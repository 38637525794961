@import '../../styles/variables';

.dob {
  flex-direction: column;

  .dob-title {
    margin-bottom: 4px;

    &.has--error {
      color: red;
    }
  }

  .is--error {
    margin-top: 16px;
    margin-bottom: 0;
  }
}

.dob-inputs {
  width: 100%;
  justify-content: space-between;
}

.dob-input {
  margin-left: 16px;

  &:first-of-type {
    margin-left: 0;
  }

  input {
    margin-bottom: 0 !important;
    padding: 0 0 0 12px; // Align centered placeholder text
  }

  &.has--error {
    color: red;
  }
}