@import '../../styles/variables';

.modal-container {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.8);
  // Changing this so we can have a modal inside a modal
  // z-index: $z-index-modal;
  z-index: 11000;

  opacity: 0;
  transition: opacity 0.1s ease-in-out;

  &.modal-enter-active,
  &.modal-enter-done {
    opacity: 1;
  }
}

.modal-button-container {
  justify-content: space-between;

  > * {
    width: 48%;
  }
}

.sn-modal {
  position: relative;

  background: linear-gradient($color-background-light, $color-background);
  box-shadow: rgb(0, 0, 0) 4px 0px 35px 0;
  padding: 40px 52px;
  max-width: 412px;
  width: 90%;

  @media only screen and (max-width: 863px) {
    padding: 24px;
  }

  border-radius: 4px;

  h1 {
    font-family: $font-title;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 28px;
    letter-spacing: 0.78px;

    margin-bottom: 32px;
  }

  p {
    margin-bottom: 32px;
  }

  &.delete-modal {
    border: 2px solid red;

    .delete-button {
      border: 2px solid transparent;
      background-image: none;
      background: red;
      color: white;

      &:hover {
        background-image: none;
        border: 2px solid white;
        background: red;
      }
    }
  }

  .exiting &.delete-modal.unmount-on-close {
    opacity: 0;
  }
}

.sn-modal__close-btn {
  position: absolute;
  top: -12px;
  right: -12px;
  height: 34px;
  width: 34px;

  border: 2px solid white;
  border-radius: 50%;

  background-color: black;

  .fa-times {
    color: white;
    font-size: 20px;
  }
}
