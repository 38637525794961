// Deprecated

.flex {
  display: flex;
}

.flex-dir-col {
  flex-direction: column;
}

.flex-items-center {
  align-items: center;
}
