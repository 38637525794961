@import 'styles/variables';

@import 'styles/button';
@import 'styles/is--loading';

// Typography
@import 'styles/fonts/rift';
@import 'styles/fonts/maison-neue';

// New Fonts
@import 'styles/fonts/inter';
@import 'styles/fonts/barlowcondensed';

body {
  color: $color-text;
  font-family: 'Inter', sans-serif !important;
  font-size: $font-size;
  // Removing the letter spacing for now
  // letter-spacing: 1.04px;
  background: no-repeat fixed linear-gradient(#1b222b, #1b222b);
}

a {
  color: $color-text;
}

.ant-input {
  // if is mobile, it should set the font-size to 16
  @media only screen and (max-width: 500px) {
    font-size: 16px;
  }
}

.password-reveal {
  z-index: $z-index-modal;
}

.iframe-container {
  flex-direction: column;
  display: flex !important;
  height: 100%;
  width: 100%;
  flex: 1 !important;
  background-color: white;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.countdown-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
}

.countdown-container > form {
  padding: 0.5rem;
}

.countdown-container > form > label {
  margin-right: 1rem;
}

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
  background-color: #ececec;
  box-shadow: 10px 10px #cfc9c9;
}

.expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 9.25rem;
  line-height: 9.75rem;
  padding: 0.5rem;
  text-decoration: none;
  color: white;
  font-family: 'Rift', sans-serif;
}

.show-counter .countdown {
  line-height: 8.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ffa300;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.ant-select-dropdown {
  z-index: 999999;
}

@media only screen and (max-width: 500px) {
  .show-counter .countdown > span {
    font-size: 1rem;
    line-height: 1rem;
  }

  .show-counter .countdown-link {
    font-size: 1.5rem;
  }
}

.ant-message-notice-success .ant-message-notice-content {
  background-color: #162312 !important;
  border: 1px solid #274916 !important;
}

.ant-message-notice-error .ant-message-notice-content {
  background-color: #281819 !important;
  border: 1px solid #532a2a !important;
}

.ant-message-notice-warning .ant-message-notice-content {
  background-color: #2b2111 !important;
  border: 1px solid #594214 !important;
}

.ant-message-notice-info .ant-message-notice-content {
  background-color: #111a2c !important;
  border: 1px solid #15325b !important;
}

.ant-message .ant-message-notice-wrapper .ant-message-success>.anticon {
  font-size: 24px !important;
}

.ant-message .ant-message-notice-wrapper .ant-message-error>.anticon {
  font-size: 16px !important;
}

.ant-message .ant-message-notice-wrapper .ant-message-warning>.anticon {
  font-size: 16px !important;
}

.ant-message .ant-message-notice-wrapper .ant-message-info>.anticon {
  font-size: 16px !important;
}

.ant-message-custom-content.ant-message-success {
  display: flex;
  align-items: center;
  text-transform: capitalize !important;
}

.ant-message-custom-content.ant-message-error {
  display: flex;
  align-items: center;
  text-transform: capitalize !important;
}

.ant-message-custom-content.ant-message-warning {
  display: flex;
  align-items: center;
  text-transform: capitalize !important;
}

.ant-message-custom-content.ant-message-info {
  display: flex;
  align-items: center;
  text-transform: capitalize !important;
}

.ant-app {
  font-family: inherit !important;
  line-height: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
  font-weight: inherit !important;
}

// remove any hover from the a ant design
a:hover {
  color: inherit !important;
}

a[disabled] {
  color: inherit !important;
}