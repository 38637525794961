$font-path: "../../../public/fonts/rift";

@font-face {
    font-family: 'Rift Soft';
    src: url("#{$font-path}/hinted-RiftSoft-LightItalic.eot");
    src: local('Rift Soft Light Italic'), local('RiftSoft-LightItalic'),
        // url("#{$font-path}/hinted-RiftSoft-LightItalic.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-RiftSoft-LightItalic.woff2") format('woff2'),
        url("#{$font-path}/hinted-RiftSoft-LightItalic.woff") format('woff'),
        url("#{$font-path}/hinted-RiftSoft-LightItalic.ttf") format('truetype'),
        url("#{$font-path}/hinted-RiftSoft-LightItalic.svg#RiftSoft-LightItalic") format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rift';
    src: url("#{$font-path}/hinted-Rift-Regular.eot");
    src: local('Rift Regular'), local('Rift-Regular'),
        // url("#{$font-path}/hinted-Rift-Regular.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-Rift-Regular.woff2") format('woff2'),
        url("#{$font-path}/hinted-Rift-Regular.woff") format('woff'),
        url("#{$font-path}/hinted-Rift-Regular.ttf") format('truetype'),
        url("#{$font-path}/hinted-Rift-Regular.svg#Rift-Regular") format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rift';
    src: url("#{$font-path}/hinted-Rift-BoldItalic.eot");
    src: local('Rift Bold Italic'), local('Rift-BoldItalic'),
        // url("#{$font-path}/hinted-Rift-BoldItalic.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-Rift-BoldItalic.woff2") format('woff2'),
        url("#{$font-path}/hinted-Rift-BoldItalic.woff") format('woff'),
        url("#{$font-path}/hinted-Rift-BoldItalic.ttf") format('truetype'),
        url("#{$font-path}/hinted-Rift-BoldItalic.svg#Rift-BoldItalic") format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rift';
    src: url("#{$font-path}/hinted-Rift-Bold.eot");
    src: local('Rift Bold'), local('Rift-Bold'),
        // url("#{$font-path}/hinted-Rift-Bold.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-Rift-Bold.woff2") format('woff2'),
        url("#{$font-path}/hinted-Rift-Bold.woff") format('woff'),
        url("#{$font-path}/hinted-Rift-Bold.ttf") format('truetype'),
        url("#{$font-path}/hinted-Rift-Bold.svg#Rift-Bold") format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rift Demi';
    src: url("#{$font-path}/hinted-Rift-Demi.eot");
    src: local('Rift Demi'), local('Rift-Demi'),
        // url("#{$font-path}/hinted-Rift-Demi.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-Rift-Demi.woff2") format('woff2'),
        url("#{$font-path}/hinted-Rift-Demi.woff") format('woff'),
        url("#{$font-path}/hinted-Rift-Demi.ttf") format('truetype'),
        url("#{$font-path}/hinted-Rift-Demi.svg#Rift-Demi") format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rift Soft';
    src: url("#{$font-path}/hinted-RiftSoft-Italic.eot");
    src: local('Rift Soft Italic'), local('RiftSoft-Italic'),
        // url("#{$font-path}/hinted-RiftSoft-Italic.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-RiftSoft-Italic.woff2") format('woff2'),
        url("#{$font-path}/hinted-RiftSoft-Italic.woff") format('woff'),
        url("#{$font-path}/hinted-RiftSoft-Italic.ttf") format('truetype'),
        url("#{$font-path}/hinted-RiftSoft-Italic.svg#RiftSoft-Italic") format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rift';
    src: url("#{$font-path}/hinted-Rift-Medium.eot");
    src: local('Rift Medium'), local('Rift-Medium'),
        // url("#{$font-path}/hinted-Rift-Medium.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-Rift-Medium.woff2") format('woff2'),
        url("#{$font-path}/hinted-Rift-Medium.woff") format('woff'),
        url("#{$font-path}/hinted-Rift-Medium.ttf") format('truetype'),
        url("#{$font-path}/hinted-Rift-Medium.svg#Rift-Medium") format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rift Soft';
    src: url("#{$font-path}/hinted-RiftSoft-Light.eot");
    src: local('Rift Soft Light'), local('RiftSoft-Light'),
        // url("#{$font-path}/hinted-RiftSoft-Light.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-RiftSoft-Light.woff2") format('woff2'),
        url("#{$font-path}/hinted-RiftSoft-Light.woff") format('woff'),
        url("#{$font-path}/hinted-RiftSoft-Light.ttf") format('truetype'),
        url("#{$font-path}/hinted-RiftSoft-Light.svg#RiftSoft-Light") format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rift Soft';
    src: url("#{$font-path}/hinted-RiftSoft-Bold.eot");
    src: local('Rift Soft Bold'), local('RiftSoft-Bold'),
        // url("#{$font-path}/hinted-RiftSoft-Bold.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-RiftSoft-Bold.woff2") format('woff2'),
        url("#{$font-path}/hinted-RiftSoft-Bold.woff") format('woff'),
        url("#{$font-path}/hinted-RiftSoft-Bold.ttf") format('truetype'),
        url("#{$font-path}/hinted-RiftSoft-Bold.svg#RiftSoft-Bold") format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rift Soft';
    src: url("#{$font-path}/hinted-RiftSoft-BoldItalic.eot");
    src: local('Rift Soft Bold Italic'), local('RiftSoft-BoldItalic'),
        // url("#{$font-path}/hinted-RiftSoft-BoldItalic.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-RiftSoft-BoldItalic.woff2") format('woff2'),
        url("#{$font-path}/hinted-RiftSoft-BoldItalic.woff") format('woff'),
        url("#{$font-path}/hinted-RiftSoft-BoldItalic.ttf") format('truetype'),
        url("#{$font-path}/hinted-RiftSoft-BoldItalic.svg#RiftSoft-BoldItalic") format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rift Demi';
    src: url("#{$font-path}/hinted-Rift-DemiItalic.eot");
    src: local('Rift Demi Italic'), local('Rift-DemiItalic'),
        // url("#{$font-path}/hinted-Rift-DemiItalic.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-Rift-DemiItalic.woff2") format('woff2'),
        url("#{$font-path}/hinted-Rift-DemiItalic.woff") format('woff'),
        url("#{$font-path}/hinted-Rift-DemiItalic.ttf") format('truetype'),
        url("#{$font-path}/hinted-Rift-DemiItalic.svg#Rift-DemiItalic") format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rift Soft';
    src: url("#{$font-path}/hinted-RiftSoft-MediumItalic.eot");
    src: local('Rift Soft Medium Italic'), local('RiftSoft-MediumItalic'),
        // url("#{$font-path}/hinted-RiftSoft-MediumItalic.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-RiftSoft-MediumItalic.woff2") format('woff2'),
        url("#{$font-path}/hinted-RiftSoft-MediumItalic.woff") format('woff'),
        url("#{$font-path}/hinted-RiftSoft-MediumItalic.ttf") format('truetype'),
        url("#{$font-path}/hinted-RiftSoft-MediumItalic.svg#RiftSoft-MediumItalic") format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rift Soft';
    src: url("#{$font-path}/hinted-RiftSoft-Regular.eot");
    src: local('Rift Soft Regular'), local('RiftSoft-Regular'),
        // url("#{$font-path}/hinted-RiftSoft-Regular.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-RiftSoft-Regular.woff2") format('woff2'),
        url("#{$font-path}/hinted-RiftSoft-Regular.woff") format('woff'),
        url("#{$font-path}/hinted-RiftSoft-Regular.ttf") format('truetype'),
        url("#{$font-path}/hinted-RiftSoft-Regular.svg#RiftSoft-Regular") format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rift';
    src: url("#{$font-path}/hinted-Rift-Italic.eot");
    src: local('Rift Italic'), local('Rift-Italic'),
        // url("#{$font-path}/hinted-Rift-Italic.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-Rift-Italic.woff2") format('woff2'),
        url("#{$font-path}/hinted-Rift-Italic.woff") format('woff'),
        url("#{$font-path}/hinted-Rift-Italic.ttf") format('truetype'),
        url("#{$font-path}/hinted-Rift-Italic.svg#Rift-Italic") format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rift';
    src: url("#{$font-path}/hinted-Rift-Light.eot");
    src: local('Rift Light'), local('Rift-Light'),
        // url("#{$font-path}/hinted-Rift-Light.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-Rift-Light.woff2") format('woff2'),
        url("#{$font-path}/hinted-Rift-Light.woff") format('woff'),
        url("#{$font-path}/hinted-Rift-Light.ttf") format('truetype'),
        url("#{$font-path}/hinted-Rift-Light.svg#Rift-Light") format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rift Soft Demi';
    src: url("#{$font-path}/hinted-RiftSoft-DemiItalic.eot");
    src: local('Rift Soft Demi Italic'), local('RiftSoft-DemiItalic'),
        // url("#{$font-path}/hinted-RiftSoft-DemiItalic.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-RiftSoft-DemiItalic.woff2") format('woff2'),
        url("#{$font-path}/hinted-RiftSoft-DemiItalic.woff") format('woff'),
        url("#{$font-path}/hinted-RiftSoft-DemiItalic.ttf") format('truetype'),
        url("#{$font-path}/hinted-RiftSoft-DemiItalic.svg#RiftSoft-DemiItalic") format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rift';
    src: url("#{$font-path}/hinted-Rift-LightItalic.eot");
    src: local('Rift Light Italic'), local('Rift-LightItalic'),
        // url("#{$font-path}/hinted-Rift-LightItalic.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-Rift-LightItalic.woff2") format('woff2'),
        url("#{$font-path}/hinted-Rift-LightItalic.woff") format('woff'),
        url("#{$font-path}/hinted-Rift-LightItalic.ttf") format('truetype'),
        url("#{$font-path}/hinted-Rift-LightItalic.svg#Rift-LightItalic") format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rift Soft';
    src: url("#{$font-path}/hinted-RiftSoft-Medium.eot");
    src: local('Rift Soft Medium'), local('RiftSoft-Medium'),
        // url("#{$font-path}/hinted-RiftSoft-Medium.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-RiftSoft-Medium.woff2") format('woff2'),
        url("#{$font-path}/hinted-RiftSoft-Medium.woff") format('woff'),
        url("#{$font-path}/hinted-RiftSoft-Medium.ttf") format('truetype'),
        url("#{$font-path}/hinted-RiftSoft-Medium.svg#RiftSoft-Medium") format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rift Soft Demi';
    src: url("#{$font-path}/hinted-RiftSoft-Demi.eot");
    src: local('Rift Soft Demi'), local('RiftSoft-Demi'),
        // url("#{$font-path}/hinted-RiftSoft-Demi.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-RiftSoft-Demi.woff2") format('woff2'),
        url("#{$font-path}/hinted-RiftSoft-Demi.woff") format('woff'),
        url("#{$font-path}/hinted-RiftSoft-Demi.ttf") format('truetype'),
        url("#{$font-path}/hinted-RiftSoft-Demi.svg#RiftSoft-Demi") format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rift';
    src: url("#{$font-path}/hinted-Rift-MediumItalic.eot");
    src: local('Rift Medium Italic'), local('Rift-MediumItalic'),
        // url("#{$font-path}/hinted-Rift-MediumItalic.eot?#iefix") format('embedded-opentype'),
        url("#{$font-path}/hinted-Rift-MediumItalic.woff2") format('woff2'),
        url("#{$font-path}/hinted-Rift-MediumItalic.woff") format('woff'),
        url("#{$font-path}/hinted-Rift-MediumItalic.ttf") format('truetype'),
        url("#{$font-path}/hinted-Rift-MediumItalic.svg#Rift-MediumItalic") format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

