@import '../../styles/variables';

.coming-soon {
  flex-direction: column;
  align-items: center;
  padding-top: 52px;

  .coming-soon-title {
    text-transform: uppercase;
    font-family: $font-title;
    font-weight: bold;
    font-size: 44px;
  }

  .coming-soon-body {
    margin-top: 16px;
    font-size: 16px;
  }

  .coming-soon-btn {
    width: 196px;
    margin-top: 30px;
  }

  .coming-soon-ghost {
    margin-top: 30px;
  }
}
