@import './variables';

.sn-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; // Sometimes this class is applied to anchors - we need this for vertical centering
  padding: 0; // Override user-agent style

  height: 30px;
  min-height: 30px;

  border: 0;
  border-radius: 2px;
  background-color: $color-text;

  font-family: 'Rift';
  font-weight: bold;
  font-size: $font-size;
  color: $color-text;
  text-transform: uppercase;
  letter-spacing: 1.64px;
  line-height: 1; // Two-line button text fits inside button

  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  &.is--outline {
    background-color: transparent !important;
    color: $color-text;
  }
}

.sn-anchor-button {
  border: none;
  padding: 0;
  background: inherit;

  cursor: pointer;
  color: inherit;
  font: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
}
