@import '../../styles/variables';
@import '../../styles/is--loading';

.invite-v2 {
  min-height: calc(100vh - #{$main-nav-height});

  font-family: $font-body;

  .invite-mobile-scroller {
    display: none;
  }

  .invite-section {
    min-height: calc(100vh - #{$main-nav-height});
    width: 58%;
    float: left;

    padding-top: 112px;
    padding-right: 5%;
    padding-bottom: 5%;
    padding-left: 5%;

    &.left {
      &:not(.remove-border) {
        border-right: $border;
      }
    }

    &.right {
      margin-top: 7px; // Line height? This aligns the right section with the left.
      width: 42%;
      align-items: center;
      padding-left: 0;
      padding-right: 0;
    }

    .accept-button {
      max-width: 300px;
      margin-top: 24px;
    }
  }

  .invite-error-message {
    margin-top: 4vh;
    font-size: 16px;
  }

  .invite-title {
    margin-bottom: 16px;
    font-family: $font-title;
    text-transform: uppercase;
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
  }

  .invite-sub-title {
    font-size: 18px;
    line-height: 20px;
    color: white;

    &.invite-join {
      margin-top: 18px;
      margin-bottom: 46px;
    }

    span.invite-highlight {
      color: $color-flair;
    }

    span.invitee-email {
      font-size: 13px;
    }
  }

  #invitev2-create-new .invite-sub-title {
    margin-bottom: 46px;
  }

  .invite-btn {
    height: 44px;

    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1.24px;
    background-image: url('../../components/Form/gradient.svg');
    border: none;

    &.is--loading {
      @include is--loading-color($color-text);
    }

    &.is-canlan {
      background-image: unset;
      font-family: $font-title;
      font-size: 20px;
      border: 2px solid $color-flair;
      border-radius: 0px;

      &:hover {
        border-color: $color-flair-light;
      }
    }
  }

  .invite-finish-btn {
    width: 50%;
    margin: 26px 0 0;
  }

  .invite-link {
    font-size: $font-size-small;
    letter-spacing: 0.29px;
  }
}

.invite-form {
  .left & {
    margin-top: 32px;
  }

  max-width: 412px;
  width: 95%;

  .invite-input {
    margin-bottom: 20px;
  }
}
