@import '../../styles/variables';

.dgli {
  margin-bottom: 24px;

  &:not(:last-of-type) .wrap {
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .align-center {
    align-self: center;
    text-align: center;
  }

  .align-right {
    text-align: right;
  }

  .game-info {
    margin-left: 0px;
    margin-right: 0px;
  }

  .indicator {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    background-color: $color-flair;
    height: 3px;
    bottom: 6px;
    display: block;
  }

  &.is-canlan .indicator {
    bottom: 0;
  }

  .sideway-text {
    color: #bcbcbc;
    font-family: $font-body;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.71px;
    line-height: 16px;
    text-align: center;
    writing-mode: vertical-lr;
    text-orientation: sideways-right;

    &.right {
      transform: rotate(180deg);
    }
  }

  .team {
    display: flex;
    align-items: center;
    width: 33%;
    max-width: 33%;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;

    &.is-right {
      margin-right: 0px;
      margin-left: 8px;
      padding-left: 0px;
      padding-right: 8px;
      flex-direction: row-reverse;
    }

    &:hover {
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.05);
    }
  }

  .team-logo {
    margin: 0 8px;
  }

  .team-name {
    font-family: $font-title;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.48px;
    line-height: 28px;
    margin: 0 18px;
    word-break: break-word;
  }

  .is-right .team-name {
    text-align: right;
  }

  .score {
    align-self: center;
    font-family: $font-title;
    font-size: 64px;
    font-weight: bold;
    letter-spacing: 1.28px;
    line-height: 64px;
    color: #a7a7a7;

    &.is-winner {
      color: #fff;
    }

    &.align-left {
      text-align: left;
    }
  }

  .center-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 33%;
    max-width: 33%;
    > * {
      width: 32%;
      max-width: 32%;
    }
  }

  .center-section-text {
    font-size: 17px;
    letter-spacing: 1.03px;
    max-width: 50%;
    width: 50%;

    &.completed {
      max-width: 36%;
      width: 36%;
      margin-top: 6px;
      color: $color-flair;
    }
  }

  .game-list-item-time {
    margin-top: 4px;
  }

  .game-number {
    color: #bcbcbc;
    font-family: $font-body;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.71px;
    margin-bottom: 8px;
  }

  .game-details {
    display: none;
    opacity: 0.6;
    font-size: 16px;
    letter-spacing: 0.8px;
    line-height: 19px;
    text-align: right;

    margin-left: auto !important;

    p:not(:first-of-type) {
      margin-top: 10px;
    }

    @media only screen and (min-width: 1072px) {
      display: block;
    }
  }

  .game-type {
    color: #bcbcbc;
    font-size: 12px;
    letter-spacing: 0.71px;
    margin-top: 8px;
  }

  .game-list-item-date {
    position: absolute;
    width: 220px;
    font-size: 16px;
  }

  .game-list-local-date {
    position: absolute;
    width: 200px;
    font-size: 13px;
    color: #bcbcbc;
  }
}
