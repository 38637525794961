@import './variables';

.is--loading {
  color: transparent !important; // We want our loader to appear on top of any other icon/text
  cursor: default;
  position: relative;
}

@keyframes loadingRotate {
	from { transform: rotate(0deg); }
	to { transform: rotate(359deg); }
}

.is--loading:after {
  position: absolute;
  content: "";
  height: 1em;
  width: 1em;
  left: calc(50% - (1em / 2));
  top: calc(50% - (1em / 2));

	border: 2px rgba($color-white, 0.25) solid;
	border-top: 2px rgba($color-white, 1) solid;
	border-radius: 50%;
	animation: loadingRotate 800ms infinite linear;
}

@mixin is--loading-color($color: $color-white) {
  &.is--loading:after {
    border-color: rgba($color, 0.25);
    border-top-color: rgba($color, 1);
  }
}

@mixin is--loading-custom($color: $color-white, $size: 1em, $border-size: 2px) {
  &.is--loading:after {
    height: $size;
    width: $size;
    left: calc(50% - (#{$size} / 2));
    top: calc(50% - (#{$size} / 2));

    border: $border-size rgba($color, 0.25) solid;
    border-top: $border-size rgba($color, 1) solid;
  }
}
