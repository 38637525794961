@import '../../styles/variables';
@import '../../styles/is--loading';

.loading-spinner {
  height: 5em;
  width: 100%;

  &.is--loading {
    @include is--loading-custom($color-flair, 5em, 4px);
  }

  &.is--fullscreen {
    position: fixed;
    top: 0;
    height: 100vh;
  }
}
